.payments-tab-container {
	padding: 20px;
	.container {
		background-color: #fff;
		height: 100%;
	}
}

.switch_inactive_user {
	&.ant-switch.ant-switch-checked {
		background-color: #c51c27;
	}
	&:hover {
		.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
			background-color: #c51c27;
		}
	}
	// .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
	// 	&:hover {
	// 		background-color: #c51c27;
	// 	}
	// }
}