/* App.css */
.modal-overlay {
    // background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    // max-width: 800px;
  }
  
  .modal-header {
    padding: 16px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    .modal-close-btn {
      cursor: pointer;
      background-color: #fff;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .modal-body {
    // padding: 16px;
  }
  