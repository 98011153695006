.profileContainer {
	background-color: white;
	height: 100%;
	.banner {
		height: 200px;
		width: 100%;
		background: rgb(77, 124, 254);
		background: linear-gradient(0deg, rgba(77, 124, 254, 1) 0%, rgba(132, 164, 252, 1) 100%);
		position: relative;
		.banner-upload {
			position: absolute;
			bottom: -40px;
			right: 40px;
			// background-color: white;
			// border-radius: 100%;
			// border: 5px solid white;
			.ant-upload-wrapper,
			.ant-upload-list {
				height: 100%;
				.ant-upload {
					// border-radius: 100%;
				}
			}
			.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
				width: 80px;
				height: 80px;
				margin: 0;
				background-color: white !important;
			}
		}

		.profileIcon {
			position: absolute;
			width: 90px;
			height: 90px;
			border-radius: 50%;
			// border: 5px solid white;
			bottom: -45px;
			left: 45px;
			z-index: 1;
			// background-color: white;
			img {
				width: 100%;
				border-radius: 50%;
				object-fit: contain;
			}
			.ant-upload-select {
				background-color: white !important;
			}
		}
	}
	.content {
		padding-top: 65px;
		.details {
			padding: 20px;
			padding-top: 0;
			.gymName {
				font-size: 2rem;
				font-weight: bold;
			}
			.ownerName {
				font-size: 1.3rem;
				font-weight: bold;
				color: #4d7cfe;
			}
			.description {
			}
			.contact {
				.email {
				}
				.mobile {
				}
			}
			.address {
			}
		}
	}
	.profile-upload {
		position: absolute;
		bottom: 0;
		right: -20px;
		width: 40px;
		height: 40px;
		background-color: white;
		border-radius: 100%;
		.ant-upload-wrapper,
		.ant-upload-list {
			height: 100%;
			.ant-upload {
				border-radius: 100%;
			}
		}
		.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}
}
