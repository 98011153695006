.ant-form-item {
	margin-bottom: 10px;
}

.ant-divider.ant-divider-horizontal {
	margin: 5px 0;
}

.custom-table {
	.ant-table-thead > tr > th,
	.ant-table-tbody > tr > .ant-table-row .ant-table tfoot > tr > th,
	.ant-table tfoot > tr > .ant-table-row {
		padding: 8px 8px 8px 8px !important;
	}
	th {
		border-top: 0 !important;
		padding: 0 0 0 30px !important;
		background: white !important;
		border-bottom: 1px solid #dddddd !important;
		font-weight: bold !important;
		color: #61616b !important;
		line-height: 1.42857 !important;
		letter-spacing: 0.7px !important;
		max-height: 35px !important;
		font-size: 12px !important;
	}

	tbody tr:hover td {
		background-color: #f5f5f5 !important;
	}

	tr > .ant-table-row {
		line-height: 1.42857 !important;
		padding: 8px !important;
		vertical-align: top !important;
		color: #2e2e2e !important;
	}
}

.ant-btn-primary {
	// display: inline-flex;
    // align-items: center;
	.anticon {
		display: inline-flex;
		align-items: center;	
	}
}