.voucher-box {
	max-width: 800px;
	margin: auto;
	padding: 20px;
	border: 1px solid #eee;
	// box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	font-size: 16px;
	line-height: 24px;
	// font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
	color: #555;
	table {
		// border: none;
	}
	.bordered-bottom {
		border: none !important;
		border-bottom: 1px solid #eee;
	}
	.voucher-footer {
		display: flex;
		justify-content: space-between;
		font-weight: bold;
		padding-top: 80px;
	}
}

.voucher-box table {
	width: 100%;
	line-height: inherit;
	text-align: left;
}

.voucher-box table td {
	// padding: 5px;
	vertical-align: top;
}

.voucher-box table tr td:nth-child(4) {
	text-align: right;
}
.voucher-box table tr td:nth-child(3) {
	text-align: right;
}

.voucher-box table tr.top table td {
	padding-bottom: 20px;
}

.voucher-box table tr.top table td.title {
	font-size: 15px;
	text-align: center;
	// line-height: 45px;

	font-weight: bold;
	color: #333;
}

.voucher-box table tr.information table td {
	padding-bottom: 20px;
}

.voucher-box table tr.heading td {
	background: #eee;
	border-bottom: 1px solid #ddd;
	font-weight: bold;
}

.voucher-box table tr.details td {
	padding-bottom: 20px;
}

.voucher-box table tr.item td {
	border-bottom: 1px solid #eee;
}

.voucher-box table tr.item.last td {
	border-bottom: none;
}

.voucher-box table tr.total td:nth-child(2) {
	border-top: 2px solid #eee;
	font-weight: bold;
}

@media only screen and (max-width: 600px) {
	.voucher-box table tr.top table td {
		width: 100%;
		display: block;
		text-align: center;
	}

	.voucher-box table tr.information table td {
		width: 100%;
		display: block;
		text-align: center;
	}
}

/** RTL **/
.voucher-box.rtl {
	direction: rtl;
	// font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.voucher-box.rtl table {
	text-align: right;
}

.voucher-box.rtl table tr td:nth-child(2) {
	text-align: left;
}

.heading,
.item,
.total {
	td:nth-child(2) {
		text-align: right;
	}
}

.information {
	td {
		table {
			tr {
				td {
					padding: 10px;
					&:nth-child(2),
					&:nth-child(4) {
						// font-weight: bold;
					}
				}
			}
		}
	}
}

.heading,
.item,
.total {
	td {
		padding: 10px;
	}
}

.top {
	td {
		padding-top: 10px;
		border-bottom: 1px solid #eee;
	}
}

.row {
	width: 100%;
	display: flex;
	.label {
		width: 45%;
		font-weight: bold;
		font-size: 14px;
	}
	.value {
		padding-left: 0;
		width: 55%;
		font-weight: bold;
	}
}
