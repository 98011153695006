.dashboard_container {
	padding: 20px;
	background-color: #f4f5f8 !important;
	// background: #f5f6f8;
	// height: 100vh;
	.title {
		font-size: 1.4rem;
		color: #1d1b51;
		font-weight: bold;
	}
	.link_container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.link {
			font-weight: bold;
			text-decoration: underline;
			color: #4d7cfe;
			cursor: pointer;
		}
	}
	.dashboard_card {
		background: white;
		min-height: 110px;
		height: 100%;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		border-radius: 6px;
		padding: 20px;
		cursor: pointer;
		transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
		&:hover {
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		}
		.icon {
			background-color: #e8f9ec;
			height: 35px;
			width: 35px;
			border-radius: 20px;
			font-size: 1.1rem;
			color: #5b9367;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.red {
			background-color: #fdf3f4 !important;
			color: #c68288 !important;
		}
		.value {
			font-size: 1.35rem;
			color: #1d1b51;
			font-weight: bold;
		}
		.name {
			font-size: 12px;
			color: #4d7cfe;
			font-weight: bold;
		}
		.desc {
			font-size: 8px;
			color: #1d1b5168;
			font-weight: 400;
		}
	}
	.dashboard_card_container {
		padding: 20px;
		&:nth-child(1) {
			display: none !important;
			.dashboard_container_card {
				background: radial-gradient(#1fe4f5, #3fbafe);
			}
		}
		&:nth-child(2) {
			.dashboard_container_card {
				background: radial-gradient(#fbc1cc, #fa99b2);
			}
		}
		&:nth-child(3) {
			.dashboard_container_card {
				background: radial-gradient(#76b2fe, #b69efe);
			}
		}
		&:nth-child(4) {
			.dashboard_container_card {
				background: radial-gradient(#60efbc, #58d5c9);
			}
		}
		&:nth-child(5) {
			.dashboard_container_card {
				background: radial-gradient(#f588d8, #c0a3e5);
			}
		}
		&:nth-child(6) {
			.dashboard_container_card {
				background: radial-gradient(#1fe4f5, #3fbafe);
			}
		}
		&:nth-child(7) {
			.dashboard_container_card {
				background: radial-gradient(#76b2fe, #b69efe);
			}
		}
	}
	&_card {
		border-radius: 10px;
		box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
		transition: all 0.2s;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 13vh;
		transform: scale(0.95);
		color: #fff;
		font-size: 1.5rem;
		.name {
			font-size: 1.2rem;
		}
		&:hover {
			box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
			transform: scale(1);
		}
	}
}

#payments {
	border-collapse: collapse;
	width: 100%;
	font-size: 1rem;
	border: none;
	box-shadow: rgb(149 157 165 / 20%) 0px 0px 12px;
	background-color: #fff;
	tr {
		&:hover {
			// background-color: #ddd;
			cursor: pointer;
		}
		&:last-child {
			td,
			th {
				border-bottom: none;
			}
		}
		th {
			border-bottom: 1px solid #ddd;
			padding-top: 12px !important;
			padding-bottom: 12px !important;
			text-align: left;
			background-color: #efefef;
			color: black;
			font-weight: bold !important;
		}
		td,
		th {
			font-weight: normal;
			font-size: 0.8rem;
			border-bottom: 1px solid #ddd;
			padding: 12px 12px;
			width: 25%;
			// border: 1px solid #ddd;
			// &:nth-child(1) {
			// 	width: 20%;
			// }
		}
		td {
			&:first-child {
				font-weight: bold;
				color: rgba(29, 27, 81, 0.4078431373);
			}
		}
	}
}

.dashboard_earning_chart {
	background-color: white;
	padding: 20px;
	padding-top: 0;
	border-radius: 0 0 8px 8px;
}

.dashboard_table {
	.ant-table-wrapper .ant-table .ant-table-title,
	.ant-table-wrapper .ant-table .ant-table-header {
		border-radius: 0 0 8px 8px !important;
	}
	.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
		border-radius: 0 0 0 0 !important;
	}
	.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
		border-radius: 0 0 0 0 !important;
	}
}
