.login_container {
	.login_image {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-top: 30px;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	.nav-link {
		font-weight: bold;
		text-decoration: underline;
		color: #4d7cfe;
	}
}
