// Override default variables before the import
$primary: #4d7cfe;

// @import "~bootstrap/scss/bootstrap.scss";

.link {
	color: $primary;
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		color: darken($primary, 10%);
	}
}
.btn-primary {
	background-color: $primary !important;
	border-color: $primary !important;
}

.btn-outline-primary {
	color: $primary !important;
	border-color: $primary !important;
}

.btn-outline-primary:hover {
	color: white !important;
	background-color: $primary !important;
	border-color: $primary !important;
}
