.ticket {
	.ant-table-tbody {
		tr {
			background-color: white !important;
		}
		tr {
			&:hover {
				// background-color:
			}
		}
		.completed {
			background-color: #b7e79f !important;
		}
		.pending {
			background-color: #fcd792 !important;
		}
		.cancelled {
			background-color: #ffdedb !important;
		}
		.open {
			background-color: #e6f6ff !important;
		}
	}
}
