/* RichTextEditor.css */

/* Editor Container */
.editor-container {
	margin: 0 auto;
	padding: 20px;
	background-color: #fff;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

/* Toolbar */
.rdw-editor-toolbar {
	/* background-color: #f4f4f4; */
	padding: 10px;
	border-radius: 8px;
	border: 1px solid #ddd;
}

.rdw-editor-toolbar button {
	padding: 20px;
	font-size: 14px;
	border: none;
	background-color: #f5f5f5;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.rdw-editor-toolbar button:hover {
	background-color: #ddd;
}

.rdw-editor-toolbar button:active {
	background-color: #ccc;
}

.rdw-option-wrapper {
    display: inline-block;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdw-editor-main {
    padding: 0 20px !important;
}

/* Editor Area */
.editor {
	min-height: 300px;
	padding: 15px;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	color: #333;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
}

/* Placeholder */
.editor .public-DraftEditorPlaceholder-root {
	font-size: 16px;
	color: #aaa;
	font-style: italic;
}

@media (max-width: 768px) {
	.editor-container {
		padding: 10px;
	}

	.editor {
		min-height: 250px;
	}
}
