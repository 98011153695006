.Toastify {
	.Toastify__toast-container {
		.Toastify__toast {
			.Toastify__toast-body {
				.push-notification {
					display: flex;
					&.SUCCESS {
						.indicator {
							background: #21a47a;
						}
						.icon {
							color: #21a47a;
						}
					}
					&.INFO {
						.indicator {
							background: #0a74b5;
						}
						.icon {
							color: #0a74b5;
						}
					}
					&.ERROR {
						.indicator {
							background: #ea2a6b;
						}
						.icon {
							color: #ea2a6b;
						}
					}
					&.WARNING {
						.indicator {
							background: #eba631;
						}
						.icon {
							color: #eba631;
						}
					}
					.indicator {
						height: auto;
						width: 1.5%;
						border-radius: 10px;
						margin-right: 10px;
					}
					.icon {
						display: flex;
						align-items: center;
						margin-right: 10px;
						font-size: 20px;
					}
					.push-notification-content {
						width: 98.5%;
						.push-notification-title {
							font-weight: bold;
							color: #000;
						}
						.push-notification-text {
							margin: 0;
						}
					}
				}
			}
		}
	}
}
