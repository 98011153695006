.personal_list_page {
	.expired {
		background-color: red;
	}

	.moreThanThirtyDays {
		background-color: #fff;
	}
	.lessThanThirtyDays {
		background-color: #fadb14;
	}
	.expired {
		background-color: rgb(255 154 155);
	}
	.ant-table-wrapper .ant-table-cell-fix-left,
	.ant-table-wrapper .ant-table-cell-fix-right {
		background: inherit;
	}
}
